import { Assignment, ItemType } from "../graphql/schema";
import { isEqual } from "lodash-es";
import { Class, FlashcardSet, Folder, Media, Note } from "../graphql/schema";
import { ExclusiveUserContent, NonNullableObject } from "../types/common";
import { fromEntries } from "./genericUtils";

export const DEFAULT_NOTE_SUMMARY = "";
export const DEFAULT_NOTE_TITLE = "";
export const DEFAULT_NOTE_CONTENT = "";

export const UNTITLED = "Untitled";
export const TITLE_FILLER = "Note";

/**
 * Removes all fields from an object that are null or undefined
 */
export const scrapeEmptyFields = <T extends Record<string, unknown>>(obj: T) => {
    return fromEntries(
        Object.entries(obj).filter(([, val]) => val !== null && val !== undefined)
    ) as NonNullableObject<T>;
};

export const deepScrapeEmptyFields = <T>(obj: T, keysWithAllowedNullValue: (keyof T)[] = []): T => {
    if (obj === null || obj === undefined) return obj;
    if (typeof obj !== "object") return obj;
    if (Array.isArray(obj))
        return obj.map(el => deepScrapeEmptyFields(el, [])).filter(el => el !== null && el !== undefined) as T;
    return Object.fromEntries(
        Object.entries(obj)
            .map(([key, val]) => [key, deepScrapeEmptyFields(val, [])])
            .filter(([key, val]) => {
                if (val === undefined) return false;

                if (keysWithAllowedNullValue.includes(key)) return true;

                if (val === null) return false;

                return true;
            })
    );
};

/**
 * given a base object, create and return a new object without the given keys
 */
export const objectWithout = <T, K extends keyof T>(baseObject: T, ...keys: K[]): Omit<T, K> => {
    const newObject = { ...baseObject };
    for (const key of keys) {
        delete newObject[key];
    }
    return newObject;
};

/**
 * pick certain keys from a given base object
 */
export const pick = <T, K extends keyof T>(baseObject: T, ...keys: K[]): Pick<T, K> => {
    if (!baseObject) return baseObject;

    const newObject = {} as Pick<T, K>;
    for (const key of keys) {
        newObject[key] = baseObject[key];
    }
    return newObject;
};

export const isNote = (
    item: FlashcardSet | Folder | Media | Note | Class | Assignment | ExclusiveUserContent | null | undefined
): item is Note => {
    if (!item) return false;

    return (
        (item as ExclusiveUserContent)?.note?.__typename === "Note" ||
        (item as FlashcardSet | Folder | Media | Note | Assignment)?.__typename === "Note" ||
        (item as any)?.itemType === ItemType.NOTE
    );
};

export const isFlashcardSet = (
    item: FlashcardSet | Folder | Media | Note | Class | Assignment | ExclusiveUserContent | null | undefined
): item is FlashcardSet => {
    if (!item) return false;
    return (
        (item as ExclusiveUserContent)?.flashcardSet?.__typename === "FlashcardSet" ||
        (item as FlashcardSet | Folder | Media | Note | Assignment)?.__typename === "FlashcardSet" ||
        (item as any)?.itemType === ItemType.FLASHCARDSET
    );
};

export const isFolder = (
    item: FlashcardSet | Folder | Media | Note | Class | Assignment | ExclusiveUserContent | null | undefined
): item is Folder => {
    if (!item) return false;

    return (
        (item as ExclusiveUserContent)?.folder?.__typename === "Folder" ||
        (item as FlashcardSet | Folder | Media | Note | Assignment)?.__typename === "Folder" ||
        (item as any)?.itemType === ItemType.FOLDER
    );
};

export const isMedia = (
    item: FlashcardSet | Folder | Media | Note | Class | Assignment | ExclusiveUserContent | null | undefined
): item is Media => {
    if (!item) return false;

    return (
        (item as ExclusiveUserContent)?.media?.__typename === "Media" ||
        (item as FlashcardSet | Folder | Media | Note | Assignment)?.__typename === "Media" ||
        (item as any)?.itemType === ItemType.MEDIA
    );
};

export const isAssignment = (
    item: FlashcardSet | Folder | Media | Note | Class | Assignment | ExclusiveUserContent | null | undefined
): item is Assignment => {
    if (!item) return false;

    return (
        (item as ExclusiveUserContent)?.assignment?.__typename === "Assignment" ||
        (item as FlashcardSet | Folder | Media | Note | Class | Assignment)?.__typename === "Assignment" ||
        (item as any)?.itemType === ItemType.ASSIGNMENT
    );
};

export const isClass = (
    item: FlashcardSet | Folder | Media | Note | Class | Assignment | ExclusiveUserContent | null | undefined
): item is Class => {
    if (!item) return false;

    return (
        (item as ExclusiveUserContent)?.course?.__typename === "Class" ||
        (item as FlashcardSet | Folder | Media | Note | Class | Assignment)?.__typename === "Class" ||
        (item as any)?.itemType === ItemType.CLASS
    );
};

export const getItemIdFromUserContentItem = (item: Note | FlashcardSet | Folder | Media | Assignment | Class) => {
    if (isNote(item)) return item.noteId;
    if (isFlashcardSet(item)) return item.flashcardSetId;
    if (isFolder(item)) return item.folderId;
    if (isMedia(item)) return item.mediaId;
    if (isAssignment(item)) return item.assignmentId;
    if (isClass(item)) return item.classId;
    return null;
};

export const getItemTypeFromUserContentItem = (
    item: Note | FlashcardSet | Folder | Media | Assignment | Class | null
) => {
    if (isNote(item)) return ItemType.NOTE;
    if (isFlashcardSet(item)) return ItemType.FLASHCARDSET;
    if (isFolder(item)) return ItemType.FOLDER;
    if (isMedia(item)) return ItemType.MEDIA;
    if (isAssignment(item)) return ItemType.ASSIGNMENT;
    if (isClass(item)) return ItemType.CLASS;
    return null;
};

export const getItemLabelFromItemType = (itemType: ItemType) => {
    if (itemType === ItemType.NOTE) return "Note";
    if (itemType === ItemType.FLASHCARDSET) return "Flashcard Set";
    if (itemType === ItemType.FOLDER) return "Folder";
    if (itemType === ItemType.MEDIA) return "Media";
    if (itemType === ItemType.ASSIGNMENT) return "Assignment";
    if (itemType === ItemType.CLASS) return "Class";
    return "";
};

/**
 * only pick the updated fields from the new object
 */

export const pickUpdatedFields = <T extends Record<string, unknown> | Record<string, unknown>[]>({
    newItem,
    oldItem,
    keys,
}: {
    oldItem: T;
    newItem: T;
    keys: string[];
}): T => {
    if (Array.isArray(newItem)) {
        return newItem.map((newItemVal, index) => {
            const oldItemVal = oldItem[index];
            return pickUpdatedFields({ newItem: newItemVal, oldItem: oldItemVal, keys });
        }) as T;
    }

    return Object.keys(newItem).reduce((acc, key) => {
        if (isEqual(newItem[key], oldItem[key]) && !keys.includes(key)) return acc;
        acc[key] = newItem[key];
        return acc;
    }, {} as T);
};

export const isValidUrl = (val: string | null | undefined) => /^(\S+):(\/\/)?\S+$/.test(val ?? "");

const specialCharRegex =
    /[\u{1D400}-\u{1D433}\u{1D434}-\u{1D467}\u{1D468}-\u{1D49B}\u{1D49C}-\u{1D4CF}\u{1D670}-\u{1D6A3}\u{1D5A0}-\u{1D5D3}\u{1D5A0}-\u{1D5D3}\u{1D7CE}-\u{1D7F5}\u{1D7D8}-\u{1D7E1}\u{1D7E2}-\u{1D7EB}\u{1D7F6}-\u{1D7FF}\u{FF10}-\u{FF19}\u{FF01}-\u{FF5E}\u{3000}]/u;

export const normalizeText = (input: string): string => {
    if (!input) return input;

    // Early exit if no special characters are found
    if (!specialCharRegex.test(input)) {
        return input;
    }

    return Array.from(input)
        .map(char => {
            const code = char.codePointAt(0);

            if (!code) return char;

            // Handle bold characters (uppercase and lowercase)
            if (code >= 0x1d400 && code <= 0x1d419) {
                // Bold uppercase A-Z (𝐀 - 𝐙)
                return String.fromCodePoint(code - 0x1d400 + 0x41); // Uppercase A-Z
            }
            if (code >= 0x1d41a && code <= 0x1d433) {
                // Bold lowercase a-z (𝐚 - 𝐳)
                return String.fromCodePoint(code - 0x1d41a + 0x61); // Lowercase a-z
            }

            // Handle italic characters (uppercase and lowercase)
            if (code >= 0x1d434 && code <= 0x1d44d) {
                // Italic uppercase A-Z (𝑨 - 𝑭)
                return String.fromCodePoint(code - 0x1d434 + 0x41); // Uppercase A-Z
            }
            if (code >= 0x1d44e && code <= 0x1d467) {
                // Italic lowercase a-z (𝑎 - 𝑧)
                return String.fromCodePoint(code - 0x1d44e + 0x61); // Lowercase a-z
            }

            // Handle monospace characters (uppercase and lowercase)
            if (code >= 0x1d670 && code <= 0x1d689) {
                // Monospace uppercase A-Z (𝙰 - 𝚉)
                return String.fromCodePoint(code - 0x1d670 + 0x41); // Uppercase A-Z
            }
            if (code >= 0x1d68a && code <= 0x1d6a3) {
                // Monospace lowercase a-z (𝚊 - 𝚣)
                return String.fromCodePoint(code - 0x1d68a + 0x61); // Lowercase a-z
            }

            // Handle bold italic characters (uppercase and lowercase)
            if (code >= 0x1d468 && code <= 0x1d481) {
                // Bold italic uppercase A-Z (𝑨 - 𝑭)
                return String.fromCodePoint(code - 0x1d468 + 0x41); // Uppercase A-Z
            }
            if (code >= 0x1d482 && code <= 0x1d49b) {
                // Bold italic lowercase a-z (𝒶 - 𝓏)
                return String.fromCodePoint(code - 0x1d482 + 0x61); // Lowercase a-z
            }

            // Handle script characters (uppercase and lowercase)
            if (code >= 0x1d49c && code <= 0x1d4b5) {
                // Script uppercase A-Z (𝒜 - 𝒵)
                return String.fromCodePoint(code - 0x1d49c + 0x41); // Uppercase A-Z
            }
            if (code >= 0x1d4b6 && code <= 0x1d4cf) {
                // Script lowercase a-z (𝒶 - 𝓏)
                return String.fromCodePoint(code - 0x1d4b6 + 0x61); // Lowercase a-z
            }

            // Handle sans-serif bold characters (uppercase and lowercase)
            if (code >= 0x1d5a0 && code <= 0x1d5b9) {
                // Sans-serif bold uppercase A-Z
                return String.fromCodePoint(code - 0x1d5a0 + 0x41); // Uppercase A-Z
            }
            if (code >= 0x1d5ba && code <= 0x1d5d3) {
                // Sans-serif bold lowercase a-z
                return String.fromCodePoint(code - 0x1d5ba + 0x61); // Lowercase a-z
            }

            // Handle bold numbers (𝟬-𝟵)
            if (code >= 0x1d7ce && code <= 0x1d7d7) {
                // Bold digits 𝟬-𝟵
                return String.fromCodePoint(code - 0x1d7ce + 0x30); // Digits 0-9
            }

            if (code >= 0x1d7ec && code <= 0x1d7f5) {
                // Bold digits 𝟬-𝟵
                return String.fromCodePoint(code - 0x1d7ec + 0x30); // Digits 0-9
            }

            // Handle double-struck numbers (𝟘-𝟡)
            if (code >= 0x1d7d8 && code <= 0x1d7e1) {
                // Double-struck digits 𝟘-𝟡
                return String.fromCodePoint(code - 0x1d7d8 + 0x30); // Digits 0-9
            }

            // Handle sans-serif numbers (𝟢-𝟫)
            if (code >= 0x1d7e2 && code <= 0x1d7eb) {
                // Sans-serif digits 𝟢-𝟫
                return String.fromCodePoint(code - 0x1d7e2 + 0x30); // Digits 0-9
            }

            // Handle monospace numbers (𝟶-𝟿)
            if (code >= 0x1d7f6 && code <= 0x1d7ff) {
                // Monospace digits 𝟶-𝟿
                return String.fromCodePoint(code - 0x1d7f6 + 0x30); // Digits 0-9
            }

            // Handle full-width numbers (０-９)
            if (code >= 0xff10 && code <= 0xff19) {
                return String.fromCodePoint(code - 0xff10 + 0x30); // 0-9
            }

            if (code === 0x3000) {
                return String.fromCodePoint(0x20); // Convert to regular space
            }

            // General handling for full-width ASCII equivalents
            if (code >= 0xff01 && code <= 0xff5e) {
                return String.fromCodePoint(code - 0xfee0); // Convert full-width to regular ASCII
            }

            // Return the character unchanged if it's not part of a specific mapped range
            return char;
        })
        .join("");
};

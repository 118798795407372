type DebugLevel = "NONE" | "MILD" | "VERBOSE";
export const DEBUG_LEVEL: DebugLevel = "NONE";

export enum GRAPHQL_AUTH_MODE {
    API_KEY = "apiKey",
    AWS_IAM = "iam",
    OPENID_CONNECT = "oidc",
    AMAZON_COGNITO_USER_POOLS = "userPool",
    AWS_LAMBDA = "lambda",
}

export class GraphQLError extends Error {
    type: string;
    errorType: string;
    path: string;

    constructor({
        name,
        message,
        errorType,
        path,
    }: {
        name: string;
        message: string;
        errorType: string;
        path: string[];
    }) {
        super(message);
        this.name = "GraphQLError";
        this.type = name;
        this.message = message;
        this.errorType = errorType;
        this.path = path?.[0];
    }
}

export type GeneratedMutation<InputType, OutputType> = string & {
    __generatedMutationInput: InputType;
    __generatedMutationOutput: OutputType;
};

export type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType;
    __generatedQueryOutput: OutputType;
};

export type GraphQLInput = {
    input?: {
        [key: string]: string | number | boolean | object;
    };
};

export type GraphQLListInput = {
    input?: {
        limit?: number;
        nextToken?: string;
        [key: string]: string | number | boolean | object;
    };
};

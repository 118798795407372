import { GraphQLError, GraphQLInput } from "../../utils/client/types";
import { prettyPrint } from "../../utils/stringUtils";
import { fromEntries } from "../../utils/genericUtils";

export const createGraphQLError = (e: any): Error | GraphQLError => {
    if (e?.errors?.length) {
        return new GraphQLError(e.errors[0]);
    }

    return e instanceof Error ? e : new Error(e);
};

export const isNetworkError = (e: Error | GraphQLError) => {
    return e.message.toLowerCase().trim() === "network error";
};

// Clean out the __typename field that appsync produces
export const clearTypenames = <T extends GraphQLInput>(variables: T): T => {
    return {
        ...variables,
        input: deepClearTypenames(variables.input),
    };
};

// Clean out the __typename field that appsync produces
const deepClearTypenames = obj => {
    if (typeof obj !== "object") {
        return obj;
    }
    const newInput = { ...obj };
    delete newInput["__typename"];
    return fromEntries(
        Object.entries(newInput).map(([key, val]) => {
            if (Array.isArray(val) && val !== null) {
                // check if the object is an array
                return [key, val.map(x => deepClearTypenames(x))];
            } else if (typeof val === "object" && val !== null) {
                return [key, deepClearTypenames(val)];
            } else {
                return [key, val];
            }
        })
    );
};

export type GraphQLListOutput = {
    [key: string]: {
        items?: object[];
        nextToken?: string;
    };
};

export const groupItems = <ItemType>(items: ItemType[], groupingKey: string) => {
    return items.reduce(
        (acc, item) => ({
            ...acc,
            [item[groupingKey]]: item,
        }),
        {}
    );
};

export const throwIfInputIsNotValid = (input, queryName) => {
    for (const [key, value] of Object.entries(input)) {
        if (value === null || value === undefined) {
            throw new Error(
                `Input to ${queryName} contains null/undefined value for ${key} with input: ${prettyPrint(input)}`
            );
        }
    }
};

import { UNTITLED } from "./dataCleaning";

export const stringWithLimit = (str: string, limit: number) => {
    return str && str.length > limit ? str.slice(0, limit) + "..." : str;
};

// returns non nullable if T is not nullable
export const capitalize = <T extends string>(word: T | null | undefined): T => {
    if (!word) return word;
    return word.toLowerCase().replace(/\b\w/g, l => l.toUpperCase()) as T;
};

export const cleanTitle = (title: string | null | undefined) => {
    if (!title) return UNTITLED;

    return (title.trim().substring(0, 40) || UNTITLED) // Ensure a default value
        .replace(/\//g, "-") // Explicitly replace all slashes first
        .replace(/\s+/g, "-") // Replace all whitespace with hyphens
        .replace(/[^-a-zA-Z0-9]+/g, ""); // Remove all other non-alphanumeric characters except "-"
};

export const escapeRegex = (str: string) => {
    return str.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const toNullableJSON = (str: string | null) => {
    return str || "{}";
};

export const isBlankString = (str: string | null | undefined) => {
    return str === null || str === undefined || str.trim() === "";
};

export const ignoreAccent = (str: string) => {
    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
};

export const numberWithCommas = (s: string) => {
    return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * convert any different type of quotes to standard double/single quote
 */
export const normalizeQuote = (str: string) => {
    const straightSingleQuote = "'";
    const straightDoubleQuote = '"';
    return str.replace(/[‘’“”]/g, match => {
        switch (match) {
            case "‘":
            case "’":
                return straightSingleQuote;
            case "“":
            case "”":
                return straightDoubleQuote;
            default:
                return match;
        }
    });
};

export const normalizeSpace = (str: string) => {
    return str.replace(/\s+/g, " ");
};

export const pluralize = (word: string, count: number) => {
    return count === 1 ? word : word + "s";
};

export const safeParse = <T>(json: string | undefined | null) => {
    if (json === undefined || json === null) return undefined;

    try {
        return json ? (JSON.parse(json) as T) : undefined;
    } catch {
        return undefined;
    }
};

export const prettyPrint = (obj: any) => {
    return JSON.stringify(obj, null, 2);
};

export const stringToNumber = (str: string) => {
    return Number(str.match(/\d/g).join(""));
};

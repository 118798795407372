import { FlashcardSide } from "@knowt/syncing/graphql/schema";
import { unstable_serialize } from "swr";
import { platform } from "../platform";
import { createGraphQLError, isNetworkError } from "../utils/client/utils";
import { QueryString } from "@knowt/syncing/types/common";

/**
 * gets random int INCLUSIVE of the min and max => [min, max]
 */
export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const fromIterableEntries = <T>(entries: IterableIterator<[string, T]>) => {
    if (!entries) return {};

    return Array.from(entries).reduce(
        (acc, [key, value]) => {
            acc[key] = value;
            return acc;
        },
        {} as Record<string, T>
    );
};

export const fromEntries = <T>(entries: [string, T][]) => {
    if (!entries) return null;

    return entries.reduce(
        (acc, [key, value]) => {
            acc[key] = value;
            return acc;
        },
        {} as Record<string, T>
    );
};

export const max = <T>(items: T[], key: string) =>
    items.reduce((max, curr, idx) => {
        if (idx === 0) return curr;
        return curr[key] > max[key] ? curr : max;
    }, null);

export const min = <T>(items: T[], key: string) =>
    items.reduce((min, curr, idx) => {
        if (idx === 0) return curr;
        return curr[key] < min[key] ? curr : min;
    }, null);

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const avg = (items: number[]) => {
    if (items.length === 0) return 0;
    return items.reduce((acc, x) => acc + x, 0) / items.length;
};

/***
 * Takes in a function and returns a debounced version of it that won't be called until there has been
 * no call to the function (with the same UID as input) for a length of time equal to debounceInterval.
 * This is useful for storing notes and preventing too many successive saves to the same note.
 */
export const debounce = (fun, debounceInterval = 1000) => {
    const callRecord = {};

    const debounceFn = (uid, ...rest) => {
        const lastCall = callRecord[uid];
        if (lastCall) {
            clearTimeout(lastCall);
        }
        callRecord[uid] = setTimeout(() => {
            fun(uid, ...rest);
            callRecord[uid] = null;
        }, debounceInterval);
    };

    return debounceFn;
};

/**
 * Takes in a function that returns a promise and retries that promise until it resolves (up until maxRetries),
 * waiting the given delay quantity.
 */
export const retry: <T>(fun: () => Promise<T>, maxRetries?: number, delay?: number) => Promise<T> = (
    fun,
    maxRetries = 3,
    delay = 2000,
    errorCreator = createGraphQLError
) => {
    return new Promise((resolve, reject) => {
        fun()
            .then(resolve)
            .catch(async e => {
                const err = errorCreator(e);
                if (maxRetries === 0 || !isNetworkError(err)) {
                    reject(err);
                    return;
                }

                await platform.analytics.logging().then(({ log }) => log(err));
                await wait(delay);

                retry(fun, maxRetries - 1, delay)
                    .then(resolve)
                    .catch(reject);
            });
    });
};

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const delay = (cb: () => void, ms: number) => new Promise(resolve => setTimeout(() => resolve(cb()), ms));

/**
 * Example: {'a': 'b', 'c': 'd'} => '?a=b&c=d'
 *          { } => ''
 */
export const createQueryStrings = (
    input: Record<string, QueryString>,
    valueParser: (value: QueryString) => string = value => value.toString()
) => {
    const filtered = Object.entries(input).filter(([_, value]) => !!value);

    if (filtered.length === 0) {
        return "";
    }

    return (
        "?" +
        filtered
            // we can safely cast because of this filter expression
            .map(([key, value]) => `${key}=${valueParser(value as string | number | boolean)}`)
            .join("&")
    );
};

/**
 * @example createArrSearchParams('id', ['1', '2', '3']) => '?id=1&id=2&id=3'
 */
export const createArrSearchParams = (id: string, val: string[]) => {
    const searchParams = new URLSearchParams(val.map<[string, string]>(v => [id, v]));
    return searchParams.toString();
};

export const renameKey = <T extends Record<string, unknown>, K extends keyof T>(obj: T, oldKey: K, newKey: string) => {
    delete Object.assign(obj, { [newKey]: obj[oldKey] })[oldKey];
};

// TODO: move to somewhere related to flashcards
export const getAnswerSide = ({
    answerWithTerm,
    answerWithDef,
}: {
    answerWithTerm: boolean;
    answerWithDef: boolean;
}) => {
    if (answerWithTerm && answerWithDef) {
        return FlashcardSide.BOTH;
    } else if (answerWithTerm) {
        return FlashcardSide.TERM;
    } else {
        return FlashcardSide.DEFINITION;
    }
};

// TODO: move to somewhere related to flashcards
export const getAnswerToggles = (answerSide?: FlashcardSide) => {
    if (!answerSide) {
        return {
            answerWithTerm: true,
            answerWithDef: false,
        };
    }

    if (answerSide === FlashcardSide.BOTH) {
        return {
            answerWithTerm: true,
            answerWithDef: true,
        };
    } else if (answerSide === FlashcardSide.TERM) {
        return {
            answerWithTerm: true,
            answerWithDef: false,
        };
    } else {
        return {
            answerWithTerm: false,
            answerWithDef: true,
        };
    }
};

export const isHexColor = (color?: string) => {
    if (!color) return false;
    return /^#([0-9a-fA-F]{6})$/.test(color);
};

/**
 * checks if given color is dark or light - might be useful for custom themes in the future
 * @param color
 */
export const isDarkColor = (color?: string) => {
    if (!color) return false;

    // Normalize to 6 digit hex
    let hex = color.replace(/^#/, "");
    if (hex.length === 3) {
        hex = hex
            .split("")
            .map(char => char + char)
            .join("");
    }

    const r = Number.parseInt(hex.substring(0, 2), 16);
    const g = Number.parseInt(hex.substring(2, 4), 16);
    const b = Number.parseInt(hex.substring(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 < 150;
};

/**
 * Adjusts the color by the given percentage.
 * @param color - The color to adjust.
 * @param percent - The percentage to adjust the color by.
 * @returns The adjusted color.
 */
export const adjustColor = (color: string, percent: number) => {
    // Normalize color to 6 digit hex
    let parsedColor = color.replace(/^#/, "");
    if (parsedColor.length === 3) {
        parsedColor = parsedColor
            .split("")
            .map(char => char + char)
            .join("");
    }
    if (parsedColor.length !== 6) {
        return color;
    }

    // Parse the RGB components
    let r = Number.parseInt(parsedColor.substring(0, 2), 16);
    let g = Number.parseInt(parsedColor.substring(2, 4), 16);
    let b = Number.parseInt(parsedColor.substring(4, 6), 16);

    // Adjust each component
    r = Math.min(255, Math.max(0, r + (r * percent) / 100));
    g = Math.min(255, Math.max(0, g + (g * percent) / 100));
    b = Math.min(255, Math.max(0, b + (b * percent) / 100));

    // Convert back to hex
    const newColor = `#${Math.round(r).toString(16).padStart(2, "0")}${Math.round(g).toString(16).padStart(2, "0")}${Math.round(b).toString(16).padStart(2, "0")}`;

    return newColor;
};

export const isFunction = <T extends (...args: any[]) => any = (...args: any[]) => any>(v: unknown): v is T =>
    // biome-ignore lint: suspicious/noDoubleEquals
    typeof v == "function";

// source: chatGPT
export const hash101 = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString().slice(0, 10); // Get a 10-digit hash
};

export const stableHash = (input: unknown) => {
    // unstable_serialize stabilizes the input, i.e. it doesn't matter if
    // { a: 1, b: 2 } is passed as { b: 2, a: 1 }, then `hash101` shortens it.
    return hash101(unstable_serialize(input));
};

export const extractNumbersFromString = (str: string) => {
    return Number(str.replace(/[^0-9.-]+/g, ""));
};

import AnonymousViewerUidInterface from "./interfaces/AnonymousViewerUid";
import DataCleaningInterface from "./interfaces/DataCleaning";
import FetchInterface from "./interfaces/Fetch";
import FetchFileBlobInterface from "./interfaces/FetchFileBlob";
import LocalKeyValueStoreInterface from "./interfaces/LocalKeyValueStore";
import TimeZoneInterface from "./interfaces/TimeZone";
import ToastInterface from "./interfaces/Toast";
import LoggingInterface from "./interfaces/analytics/Logging";
import MixpanelInterface from "./interfaces/analytics/Mixpanel";
import noop from "./utils/noop";

export enum PlatformType {
    WEB,
    MOBILE,
    CHROME_EXTENSION,
}

export type Config = {
    storage: () => Promise<LocalKeyValueStoreInterface>;
    toast: () => Promise<ToastInterface>;
    fetchFileBlob: () => Promise<FetchFileBlobInterface>;
    fetch: () => Promise<FetchInterface>;
    timezone: () => Promise<TimeZoneInterface>;
    anonymousViewerUid: () => Promise<AnonymousViewerUidInterface>;
    dataCleaning: () => Promise<DataCleaningInterface>;
    analytics: {
        mixpanel: () => Promise<MixpanelInterface>;
        logging: () => Promise<LoggingInterface>;
    };
    events?: () => Promise<{
        on?: (event: string, callback: () => void) => void;
        off?: (event: string, callback: () => void) => void;
    }>;
    platformType?: PlatformType;
};

export const setUser = user => {
    // biome-ignore lint: noConsole
    console.log("setUser", user);
};

// to allow logging on server side without configuring platform
export const log = (key, ...extras) => {
    // biome-ignore lint: noConsole
    console.log(key, ...extras);
};

export const forceLog = (key, ...extras) => {
    // biome-ignore lint: noConsole
    console.log(key, ...extras);
};

export const warn = (key, ...extras) => {
    // biome-ignore lint: noConsole
    console.warn(key, ...extras);
};

export const forceWarn = (key, ...extras) => {
    // biome-ignore lint: noConsole
    console.warn(key, ...extras);
};

class Platform {
    public storage: Config["storage"] = () =>
        Promise.resolve({
            get: null,
            find: null,
            set: null,
            getWithExpiry: null,
            remove: null,
            setWithExpiry: null,
        });

    public toast: Config["toast"] = () =>
        Promise.resolve({
            error: null,
            success: null,
            loading: null,
            custom: null,
            dismiss: null,
            remove: null,
            promise: null,
            xpToast: null,
        });

    public events?: Config["events"] = () => Promise.resolve({ on: null, off: null });
    public timezone: Config["timezone"] = () => Promise.resolve({ getLocalTZ: null });
    public fetchFileBlob: Config["fetchFileBlob"] = () => Promise.resolve(null);
    public fetch: Config["fetch"] = () => Promise.resolve(null);
    public anonymousViewerUid?: Config["anonymousViewerUid"] = () => Promise.resolve({ get: null });
    public analytics: Config["analytics"] = {
        mixpanel: () =>
            Promise.resolve({
                track: async () => noop(),
                identify: noop,
                alias: noop,
                people: {
                    set: noop,
                },
                reset: noop,
                init: noop,
            }),
        logging: () =>
            Promise.resolve({
                setUser,
                log,
                forceLog,
                warn,
                forceWarn,
                report: noop,
                onAppsyncError: noop,
            }),
    };

    public dataCleaning: Config["dataCleaning"] = async () =>
        Promise.resolve({
            getHtmlFromContent: () => "",
            getPlainTextFromContent: () => "",
        });

    public platformType: Config["platformType"] = null;

    public configure(config: Config) {
        this.storage = config.storage;
        this.toast = config.toast;
        this.fetch = config.fetch;
        this.events = config.events;
        this.timezone = config.timezone;
        this.fetchFileBlob = config.fetchFileBlob;
        this.anonymousViewerUid = config.anonymousViewerUid;
        this.dataCleaning = config.dataCleaning;
        this.analytics = config.analytics;
        this.platformType = config.platformType;
    }
}

export const platform = new Platform();
